@font-face {
  font-family: 'interplay-icons';
  src:  url('fonts/interplay-icons.eot?y8mqw4');
  src:  url('fonts/interplay-icons.eot?y8mqw4#iefix') format('embedded-opentype'),
    url('fonts/interplay-icons.ttf?y8mqw4') format('truetype'),
    url('fonts/interplay-icons.woff?y8mqw4') format('woff'),
    url('fonts/interplay-icons.svg?y8mqw4#interplay-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'interplay-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-UEFA-Europa-League-_1_:before {
  content: "\e944";
}
.icon-bell_notification .path1:before {
  content: "\e93c";
  color: rgb(255, 255, 255);
}
.icon-bell_notification .path2:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-bell_notification .path3:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(149, 251, 63);
}
.icon-supp:before {
  content: "\e932";
}
.icon-heart_full:before {
  content: "\e93d";
}
.icon-home:before {
  content: "\e93e";
}
.icon-instagram:before {
  content: "\e940";
}
.icon-whatsapp:before {
  content: "\e941";
}
.icon-crypto:before {
  content: "\e929";
}
.icon-out:before {
  content: "\e931";
}
.icon-phone:before {
  content: "\e93f";
}
.icon-arrow_left:before {
  content: "\e923";
}
.icon-arrow_right:before {
  content: "\e924";
}
.icon-bank:before {
  content: "\e925";
}
.icon-cash:before {
  content: "\e926";
}
.icon-check:before {
  content: "\e927";
}
.icon-credit:before {
  content: "\e928";
}
.icon-dart:before {
  content: "\e92a";
}
.icon-diamond:before {
  content: "\e92b";
}
.icon-facebook:before {
  content: "\e92c";
}
.icon-fire:before {
  content: "\e92d";
}
.icon-google:before {
  content: "\e92e";
}
.icon-identity:before {
  content: "\e92f";
}
.icon-mail:before {
  content: "\e930";
}
.icon-ping:before {
  content: "\e933";
}
.icon-rocket2:before {
  content: "\e934";
}
.icon-seven:before {
  content: "\e935";
}
.icon-slot:before {
  content: "\e936";
}
.icon-star_face:before {
  content: "\e937";
}
.icon-transaction:before {
  content: "\e938";
}
.icon-user:before {
  content: "\e939";
}
.icon-user2:before {
  content: "\e93a";
}
.icon-withdraw:before {
  content: "\e93b";
}
.icon-bell:before {
  content: "\e91b";
}
.icon-calendar:before {
  content: "\e91c";
}
.icon-compass:before {
  content: "\e91d";
}
.icon-flame:before {
  content: "\e91e";
}
.icon-heart:before {
  content: "\e91f";
}
.icon-rocket:before {
  content: "\e920";
}
.icon-star:before {
  content: "\e921";
}
.icon-wallet:before {
  content: "\e922";
}
.icon-pencil:before {
  content: "\e91a";
}
.icon-logo_afa:before {
  content: "\e90d";
}
.icon-logo_bundesliga:before {
  content: "\e90e";
}
.icon-logo_championsleague:before {
  content: "\e90f";
}
.icon-logo_championsleague1:before {
  content: "\e945";
}
.icon-logo_europa_league:before {
  content: "\e910";
}
.icon-logo_laliga:before {
  content: "\e911";
}
.icon-logo_laliga_sm:before {
  content: "\e946";
}
.icon-logo_ligue01:before {
  content: "\e912";
}
.icon-logo_mls:before {
  content: "\e913";
}
.icon-logo_nba:before {
  content: "\e914";
}
.icon-logo_nfl:before {
  content: "\e915";
}
.icon-logo_nhl:before {
  content: "\e916";
}
.icon-logo_premierleague:before {
  content: "\e917";
}
.icon-logo_premierleague1:before {
  content: "\e947";
}
.icon-logo_serieA:before {
  content: "\e918";
}
.icon-logo_ufc:before {
  content: "\e919";
}
.icon-coins_line:before {
  content: "\e908";
}
.icon-info:before {
  content: "\e909";
}
.icon-ball:before {
  content: "\e900";
}
.icon-basketball:before {
  content: "\e901";
}
.icon-cards:before {
  content: "\e902";
}
.icon-hockey:before {
  content: "\e903";
}
.icon-joystick:before {
  content: "\e904";
}
.icon-live:before {
  content: "\e905";
}
.icon-prom:before {
  content: "\e906";
}
.icon-roulette:before {
  content: "\e907";
}
.icon-tennil:before {
  content: "\e90a";
}
.icon-trophy:before {
  content: "\e90b";
}
.icon-voleibol:before {
  content: "\e90c";
}
